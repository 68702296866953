<template>
  <v-card class="min-w-0">
    <v-text-field
      v-model="filter"
      class="pa-1 py-2 todo-filter elevation-1"
      placeholder="Hae tehtävää"
      prepend-inner-icon="mdi-magnify"
      hide-details
      block
      clearable
      solo
      flat
    ></v-text-field>

    <v-divider></v-divider>

    <div v-if="tasks.length === 0">
      <div class="px-1 py-6 text-center">Ei tehtäviä!</div>
    </div>

    <v-slide-y-transition v-else group tag="div">
      <div
        v-for="task in visibleTasks"
        :key="task._id"
        class="d-flex pa-2 task-item align-center"
        @click="$emit('edit-task', task)"
      >
        <v-checkbox
          :input-value="task.completed"
          class="mt-0 mr-1 pt-0"
          hide-details
          off-icon="mdi-checkbox-blank-circle-outline"
          on-icon="mdi-checkbox-marked-circle"
          @click.stop="task.completed ? setIncomplete(task) : setComplete(task)"
        ></v-checkbox>

        <div class="task-item-content flex-grow-1" :class="{ complete: task.completed }">
          <div class="font-weight-bold" style="margin-bottom: -1px">
            {{ task.title }}
            <span v-if="task.apartment">
              (<span>{{ task.apartment.address }}</span
              ><span>{{
                task.apartment.apartmentNumber ? `, ${task.apartment.apartmentNumber}` : ""
              }}</span
              >)
            </span>
          </div>

          <div
            class="font-weight-light item-content-center"
            style="font-size: 13px; white-space: pre-wrap"
            v-text="task.subtitle"
          ></div>

          <div class="error--text" style="margin-bottom: -5px; margin-top: 5px">
            <small>{{ formatDate(task.dueDate) }}</small>
          </div>

          <div>
            <v-chip
              v-for="label in task.labels"
              :key="label"
              :color="getLabelColor(label)"
              class="font-weight-bold mt-1 mr-1"
              outlined
              x-small
            >
              {{ getLabelTitle(label) }}
            </v-chip>
          </div>
        </div>

        <div class="d-flex align-center justify-center ml-1">
          <v-btn icon @click.stop="deteleTodo(task)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";

/*
|---------------------------------------------------------------------
| ToDo List Component
|---------------------------------------------------------------------
|
| Task lister
|
*/
export default {
  props: {
    // task list
    tasks: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [mixins],

  data() {
    return {
      filter: "",
    };
  },

  computed: {
    ...mapState("todoApp", ["labels"]),

    visibleTasks() {
      if (!this.filter) return this.tasks;

      return this.tasks.filter((t) => {
        return Boolean(
          Object.values(t)
            .filter((prop) => typeof prop === "string")
            .find((item) => item.includes(this.filter))
        );
      });
    },
  },

  methods: {
    ...mapActions("todoApp", {
      setComplete: "taskCompleted",
      setIncomplete: "taskIncomplete",
      deleteTask: "deleteTask",
    }),

    deteleTodo(task) {
      if (confirm("Haluatko varmasti poistaa tehtävän?")) {
        this.deleteTask(task);
      }
    },

    getLabelColor(id) {
      const label = this.labels.find((l) => l.id === id);

      return label ? label.color : "";
    },

    getLabelTitle(id) {
      const label = this.labels.find((l) => l.id === id);

      return label ? label.title : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-filter {
  position: sticky;
  background-color: var(--v-background-base) !important;
  z-index: 2;
  top: 0;
}

.task-item {
  cursor: pointer;
  border-bottom: 1px solid rgba(100, 100, 100, 0.1);

  &:hover {
    background-color: rgba(209, 209, 209, 0.1);
  }

  .task-item-content {
    &.complete {
      text-decoration: line-through;
    }
  }
}
</style>
