<template>
  <todo-list :tasks="completeTasks" @edit-task="$emit('edit-task', $event)" />
</template>

<script>
import { mapGetters } from "vuex";
import TodoList from "../../components/ToDo/TodoList";

export default {
  components: {
    TodoList,
  },
  computed: {
    ...mapGetters("todoApp", ["completeTasks"]),
  },
};
</script>
